import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/micrositios.png";
import elementos from "../../../../static/img/blog/elementos-ir-1140.jpg";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    console.log(props)
    return (
        <Single location={props.location} title='Elementos relevantes en sitios de RI'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={elementos}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                10 Dic. 2019
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : J.A. Lovera
                </span>
                                <span>
                    Herramientas de RI
                </span>
                            </div>
                        </div>

                        <h2>Elementos de mayor importancia en sitios de RI, y la mejor manera de presentarlos.</h2>
                    </div>
                </div>
                <div className="s_main_text">
                    <p>
                        Respecto a los elementos de mayor importancia, definitivamente el contenido manda. A pesar de
                        que tickers y
                        gráficos en el sitio de RI son elementos deseables, la ponderación de su utilidad para el
                        inversionista es
                        menor(**); inversionistas retail usan como fuente de consulta a los portales financieros
                        gratuitos, como
                        Yahoo Finance, mientras inversionistas institucionales usan portales especializados, como
                        Bloomberg.
                    </p>
                    <p>Los resultados del “Shareholder Confidence 365 Study, fall 2013”* también nos da una idea clara
                        de la
                        importancia del sitio de RI para la búsqueda de información: más de 20% de los inversionistas
                        consulta los
                        resultados intermedios no auditados y reportes regulatorios directamente del sitio de RI; y,
                        34.9% escucha
                        el re-play de teleconferencias de resultados desde el sitio de RI. Otros resultados reveladores,
                        arrojados
                        por el estudio arriba mencionado, se refieren al uso de los transcripts de la llamada de
                        resultados, ya que
                        el 47.9% de los inversionistas encuestados afirma consultarlos, otro hecho revelador corresponde
                        a la
                        demanda de video webcasts, aproximadamente al 70% de los inversionistas encuestados les gustaría
                        tener
                        acceso en video al mensaje del CEO (TD Bank fue laureado en 2012 como la mejor empresa
                        canadiense en
                        prácticas de RI, en parte por el uso de video webcasts en su reporte anual).</p>
                    <p>Respecto a la manera de cómo presentar el contenido del sitio de RI, los inversionistas valoran
                        el empleo
                        de las siguientes prácticas:</p>

                    <img className="w-100 wow fadeIn" src={info1} alt="" data-wow-delay="0.2s" data-wow-duration="2.0s"/>
                    <p className={"mt-10"}>Un sitio de RI que contenga un contenido bien estructurado y presentado puede
                        constituir la herramienta más valiosa de su departamento de RI.</p>
                    <small>(*)Encuesta preparada por la Dirección de Marketing de Vintage, CNW y PR Newswire, en otoño
                        de
                        2013, bajo el
                        título “How are investors consuming your investor relations content?”. Para la preparación de
                        esta encuesta
                        se distribuyó un formulario a 20,000 individuos e inversionistas profesionales.
                    </small>
                    <br/>
                    <small>(**)De acuerdo con el “Shareholder Confidence 365 Study, fall 2013”, tan sólo 0.4% de los
                        individuos
                        encuestados usó el sitio de RI para consulta de fluctuación en los parámetros de los
                        “securities” de una
                        emisora, a través de un ticker.
                    </small>

                </div>
            </div>
        </Single>
    )
}

export default Detail
